import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import EditableContent from "../../../../../components/editable-content"
import Heading from "../../../../../components/heading"
import LayoutCourse from "../../../../../layouts/course"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Text from "../../../../../components/text"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/spiel-mit-versgrenzen/loesung" />
      }
      navigation={navigation}
    >
      <Seo title="Versgrenzen setzen" />
      <Stack>
        <Heading as="h2" level={2}>
          Versgrenzen setzen
        </Heading>
        <Paragraph>
          Ein Gedicht erhält seine markante Form insbesondere dadurch, dass es
          in Zeilen oder Verse gegliedert ist. Das unterscheidet es schon auf
          den ersten Blick von einem Prosatext. Hier haben wir die
          Zeilenumbrüche aus Hölderlins Gedicht herausgenommen. Versuche, sie
          wieder richtig einzusetzen.
        </Paragraph>
        <TaskSidebar
          main={
            <PaperStack p={[3, 4]}>
              <EditableContent
                fontSize={[3, 3, 4]}
                p={[3, 4]}
                fontFamily="serif"
                contentChanged={({ htmlContent }) => {
                  setAnswer({
                    courseId: "haelfte-des-lebens",
                    chapterId: "01-zwei-haelften",
                    taskId: "spiel-mit-versgrenzen",
                    answer: htmlContent,
                  })
                }}
                initialContent="Mit gelben Birnen hänget und voll mit wilden Rosen das Land in den See, ihr holden Schwäne, und trunken von Küssen tunkt ihr das Haupt ins heilignüchterne Wasser. Weh mir, wo nehm’ ich, wenn es Winter ist, die Blumen, und wo den Sonnenschein, und Schatten der Erde? Die Mauern stehn sprachlos und kalt, im Winde klirren die Fahnen."
              />
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              <Stack space={3}>
                <p>Bringe den Fließtext erneut in die Form des Gedichts.</p>
                <Text size={2}>
                  Klicke dazu in das Textfeld und gehe zu der Stelle, an der du
                  einen Zeilenumbruch hinzufügen möchtest.
                </Text>
                <Text size={2}>
                  Verwende einen einfachen Zeilenumbruch ↩ für ein Versende und
                  einen doppelten Zeilenumbruch ↩↩ für ein Strophenende.
                </Text>
              </Stack>
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
